<template>
    <div class="container">
        <div class="filter">
            <div class="filters">
                <div class="filter-group">
                    <b-form-group id="textGroup"
                                  label="I'm looking for"
                                  label-for="location">
                        <b-form-input id="location"
                                      type="text"
                                      v-model="title"
                                      placeholder="Event Name">
                        </b-form-input>
                    </b-form-group>

                    <b-form-group id="textGroup"
                                  label="By tag"
                                  label-for="location">
                        <b-form-input id="location"
                                      type="text"
                                      v-model="tag"
                                      placeholder="Event Tag">
                        </b-form-input>
                    </b-form-group>

                    <b-form-group id="locationGroup"
                                  label="In"
                                  label-for="location">
                        <b-form-input id="location"
                                      type="text"
                                      v-model="location"
                                      placeholder="Enter location e.g. Boston, UK">
                        </b-form-input>
                    </b-form-group>
                </div>

                <div class="filter-group">
                    <div class="form-group">
                        <label for="dateFrom">On</label>
                        <div class="input-group">
                            <el-date-picker size="large" name='startDate' v-model="dateOn" type="date" format="dd/MM/yyyy" placeholder="Select Event Date">
                            </el-date-picker>
                        </div>
                    </div>

                    <b-form-group id="typeSelectorGroup"
                                  label="Event type"
                                  label-for="typeSelector">
                        <b-form-select id="typeSelector"
                                       :options="[{ text: 'All', value: null }].concat(eventTypeOptions)"
                                       v-model="eventType">
                        </b-form-select>
                    </b-form-group>

                    <b-form-group id="costSelectorGroup"
                                  label="By Price"
                                  label-for="costSelector">
                        <!-- <b-form-radio-group id="costSelector" v-model="cost" :options="costOptions" name="costOptionsRadio"></b-form-radio-group> -->
                        <b-form-select id="costSelector"
                                       :options="costOptions"
                                       v-model="cost">
                        </b-form-select>
                    </b-form-group>
                </div>
            </div>
            <div class="buttons-group">
                <button type="button" @click="filterEvents" class="btn btn-secondary" id="search-btn">
                    <span><i class="fa fa-search"></i></span>
                </button>
                <a @click="clearFilters">Clear filters</a>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                placeHolderText: "Filter text",
                title: "",
                dateOn: null,
                location: null,
                tag: null,
                eventType: null,
                eventTypeOptions: [],
                cost: 'all',
                costOptions: [
                    {text: 'all', value: 'all'},
                    {text: 'free', value: 'free'},
                    {text: 'paid', value: 'paid'}
                ]
            };
        },
        created() {
            var self = this;
            self.getEventTypeOptions();

        },
        methods: {
            filterEvents() {
                this.$emit("filterEvents", {title: this.title, date_on: this.dateOn, location: this.location, tag: this.tag, type: this.eventType, cost: this.cost});
            },
            clearFilters() {
                this.placeHolderText = "Filter text";
                this.title = "";
                this.dateOn = null;
                this.location = null,
                this.tag = null,
                this.eventType = null,
                this.cost = 'all'
                this.filterEvents();
            },
            getEventTypeOptions(){
                var self = this;

                this.$http
                    .get("/splash_screen/event_type_selector_options.json")
                    .then(function(response) {
                        console.log(response.data);
                        self.eventTypeOptions = response.data;
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            }
        }
    };
</script>